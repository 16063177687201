import React from "react";
import PropTypes from "prop-types";
import { MapPadding } from "react-openlayers";
import { provideViewSize } from "../hocs/provideViewSize";
import { withRouter } from "react-router-dom";
import Features from "../components/Features";
import Search from "../components/Search";
import "./CoreLayout.scss";

export class MapLayout extends React.Component {
  calcMapPadding() {
    const { viewHeight, viewWidth } = this.props;
    if (viewWidth < 769) {
      return [50, 0, (6 * viewHeight) / 10, 0];
    }
    if (viewWidth < 1260) {
      return [0, 400, 0, 0];
    }
    return [0, 440, 0, 0];
  }

  render() {
    const { children } = this.props;
    return (
      <div className="core-layout--root">
        <MapPadding padding={this.calcMapPadding()} />
        <Features />
        <Search />
        <div className="core-layout--components">{children}</div>
      </div>
    );
  }
}

MapLayout.propTypes = {
  children: PropTypes.any,
  viewWidth: PropTypes.number,
  viewHeight: PropTypes.number,
  history: PropTypes.object.isRequired,
};

export default provideViewSize(withRouter(MapLayout));
