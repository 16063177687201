import useArticleConfig from "./useArticleConfig";
import request from "superagent";
import { useState, useEffect } from "react";
import config from "../config";
import { olUtils } from "react-openlayers";

const cache = {
  articles: {},
  media: {},
  loaded: false,
};

export default function useArticles() {
  const articleConfig = useArticleConfig(config.articleModuleUuid);
  const [articles, setArticles] = useState(
    cache.loaded ? Object.values(cache.articles) : null
  );

  useEffect(() => {
    if (!articleConfig) return;

    if (cache.loaded) {
      setArticles(Object.values(cache.articles));
      return;
    }

    request
      .post(config.adaptiveUrl + "WebServices/client/DataView.asmx/ReadAny")
      .send({
        request: {
          srid: 3857,
          theme_uuid: articleConfig.value.uuid,
          columns: [...articleConfig.columns, "uuid"],
        },
        filter: {},
      })
      .then((res) => res.body)
      .then((res) => {
        if (!res.d || !res.d.success) {
          console.error(res.d);
          setArticles([]);
          return;
        }

        res.d.records.forEach((record) => {
          record.feature = olUtils.createFeatureFromWkt(record.geom_wkt, {
            id: record.id,
          });
          cache.articles[record.id] = record;
        });

        cache.loaded = true;

        setArticles(res.d.records);
      })
      .catch((err) => {
        console.error(err);
        setArticles([]);
      });
  }, [articleConfig]);

  return { articles, loading: articles === null };
}

export function useArticle(articleId) {
  const articleConfig = useArticleConfig(config.articleModuleUuid);
  const [article, setArticle] = useState(null);

  useEffect(() => {
    if (!articleConfig) return;

    if (cache.articles[articleId]) {
      setArticle(cache.articles[articleId]);
      return;
    }

    request
      .post(config.adaptiveUrl + "WebServices/client/DataView.asmx/ReadAny")
      .send({
        request: {
          srid: 3857,
          theme_uuid: articleConfig.value.uuid,
          columns: [...articleConfig.columns, "uuid"],
          object_id: articleId,
        },
        filter: {},
      })
      .then((res) => res.body)
      .then((res) => {
        if (!res.d || !res.d.success) {
          console.error(res.d);
          setArticle(false);
          return;
        }

        const record = res.d.records[0];
        record.feature = olUtils.createFeatureFromWkt(record.geom_wkt, {
          id: record.id,
        });
        cache.articles[articleId] = record;
        setArticle(record);
      })
      .catch((err) => {
        console.error(err);
        setArticle(false);
      });
  }, [articleId, articleConfig]);

  return { article, loading: article === null };
}

export function useArticleMedia(uuid) {
  const articleConfig = useArticleConfig(config.articleModuleUuid);
  const [media, setMedia] = useState(null);

  useEffect(() => {
    if (!articleConfig || !uuid) return;
    if (cache.media[uuid]) {
      setMedia(cache.media[uuid]);
      return;
    }

    request
      .post(config.adaptiveUrl + "WebServices/generic/Media.asmx/Read")
      .send({
        start: 0,
        limit: 0,
        filter: {},
        extraParams: [
          { key: "parent_object_uuid", value: config.digiThemeUuid },
          { key: "owner_uuid", value: uuid },
          { key: "parent_object_type", value: "digi_theme" },
        ],
      })
      .then((res) => res.body)
      .then((res) => {
        if (!res.d || !res.d.success) {
          console.error(res.d);
          setMedia(false);
          return;
        }

        cache.media[uuid] = res.d.records;
        setMedia(res.d.records);
      })
      .catch((err) => {
        console.error(err);
        setMedia(false);
      });
  }, [uuid, articleConfig]);

  return { media, loading: media === null };
}
