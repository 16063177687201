import React from "react";
import PropTypes from "prop-types";
import "./Icon.scss";

const icons = {
  search: (
    <svg viewBox="0 0 32 32">
      <path d="M31.008 27.231l-7.58-6.447c-0.784-0.705-1.622-1.029-2.299-0.998 1.789-2.096 2.87-4.815 2.87-7.787 0-6.627-5.373-12-12-12s-12 5.373-12 12 5.373 12 12 12c2.972 0 5.691-1.081 7.787-2.87-0.031 0.677 0.293 1.515 0.998 2.299l6.447 7.58c1.104 1.226 2.907 1.33 4.007 0.23s0.997-2.903-0.23-4.007zM12 20c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8z" />
    </svg>
  ),
  pencil: (
    <svg viewBox="0 0 32 32">
      <path d="M27 0c2.761 0 5 2.239 5 5 0 1.126-0.372 2.164-1 3l-2 2-7-7 2-2c0.836-0.628 1.874-1 3-1zM2 23l-2 9 9-2 18.5-18.5-7-7-18.5 18.5zM22.362 11.362l-14 14-1.724-1.724 14-14 1.724 1.724z" />
    </svg>
  ),
  unlock: (
    <svg viewBox="0 0 32 32">
      <path d="M24 2c3.308 0 6 2.692 6 6v6h-4v-6c0-1.103-0.897-2-2-2h-4c-1.103 0-2 0.897-2 2v6h0.5c0.825 0 1.5 0.675 1.5 1.5v15c0 0.825-0.675 1.5-1.5 1.5h-17c-0.825 0-1.5-0.675-1.5-1.5v-15c0-0.825 0.675-1.5 1.5-1.5h12.5v-6c0-3.308 2.692-6 6-6h4z" />
    </svg>
  ),
  checkmark: (
    <svg viewBox="0 0 32 32">
      <path d="M27 4l-15 15-7-7-5 5 12 12 20-20z" />
    </svg>
  ),
  userplus: (
    <svg viewBox="0 0 32 32">
      <path d="M12 23c0-4.726 2.996-8.765 7.189-10.319 0.509-1.142 0.811-2.411 0.811-3.681 0-4.971 0-9-6-9s-6 4.029-6 9c0 3.096 1.797 6.191 4 7.432v1.649c-6.784 0.555-12 3.888-12 7.918h12.416c-0.271-0.954-0.416-1.96-0.416-3z" />
      <path d="M23 14c-4.971 0-9 4.029-9 9s4.029 9 9 9c4.971 0 9-4.029 9-9s-4.029-9-9-9zM28 24h-4v4h-2v-4h-4v-2h4v-4h2v4h4v2z" />
    </svg>
  ),
  userminus: (
    <svg viewBox="0 0 32 32">
      <path d="M12 23c0-4.726 2.996-8.765 7.189-10.319 0.509-1.142 0.811-2.411 0.811-3.681 0-4.971 0-9-6-9s-6 4.029-6 9c0 3.096 1.797 6.191 4 7.432v1.649c-6.784 0.555-12 3.888-12 7.918h12.416c-0.271-0.954-0.416-1.96-0.416-3z" />
      <path d="M23 14c-4.971 0-9 4.029-9 9s4.029 9 9 9c4.971 0 9-4.029 9-9s-4.029-9-9-9zM28 24h-10v-2h10v2z" />
    </svg>
  ),
  cross: (
    <svg viewBox="0 0 16 16">
      <path d="M13.957 3.457l-1.414-1.414-4.543 4.543-4.543-4.543-1.414 1.414 4.543 4.543-4.543 4.543 1.414 1.414 4.543-4.543 4.543 4.543 1.414-1.414-4.543-4.543z" />
    </svg>
  ),
  profile: (
    <svg viewBox="0 0 1000 1000">
      <path d="M670.3,574.2c82.9-55.2,137.6-149.4,137.6-256.4c0-170-137.8-307.8-307.8-307.8c-170,0-307.8,137.8-307.8,307.8c0,107,54.7,201.2,137.6,256.4C171.5,637.4,59,786.3,53.2,961.3c0,0-7.6,33.2,55.2,28c26.9-2.2,33.7-28,33.7-28c29.8-190.7,178.5-335.8,358-335.8c179.5,0,328.2,145.1,358,335.8c0,0,17.4,26.1,33.7,28c51.3,5.9,55.2-28,55.2-28C941.3,786.3,828.8,637.4,670.3,574.2z M500.1,541.6c-123.6,0-223.8-100.2-223.8-223.8c0-123.6,100.2-223.8,223.8-223.8C623.8,93.9,724,194.2,724,317.8C724,441.4,623.8,541.6,500.1,541.6z" />
    </svg>
  ),
  share: (
    <svg viewBox="0 0 32 32">
      <path d="M8 20c0 0 1.838-6 12-6v6l12-8-12-8v6c-8 0-12 4.99-12 10zM22 24h-18v-12h3.934c0.315-0.372 0.654-0.729 1.015-1.068 1.374-1.287 3.018-2.27 4.879-2.932h-13.827v20h26v-8.395l-4 2.667v1.728z" />
    </svg>
  ),
  facebook: (
    <svg viewBox="0 0 32 32">
      <path d="M29 0h-26c-1.65 0-3 1.35-3 3v26c0 1.65 1.35 3 3 3h13v-14h-4v-4h4v-2c0-3.306 2.694-6 6-6h4v4h-4c-1.1 0-2 0.9-2 2v2h6l-1 4h-5v14h9c1.65 0 3-1.35 3-3v-26c0-1.65-1.35-3-3-3z" />
    </svg>
  ),
  load: (
    <svg viewBox="0 0 32 32">
      <path d="M12 4c0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.209-1.791 4-4 4s-4-1.791-4-4zM20.485 7.515c0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.209-1.791 4-4 4s-4-1.791-4-4zM26 16c0-1.105 0.895-2 2-2s2 0.895 2 2c0 1.105-0.895 2-2 2s-2-0.895-2-2zM22.485 24.485c0-1.105 0.895-2 2-2s2 0.895 2 2c0 1.105-0.895 2-2 2s-2-0.895-2-2zM14 28c0 0 0 0 0 0 0-1.105 0.895-2 2-2s2 0.895 2 2c0 0 0 0 0 0 0 1.105-0.895 2-2 2s-2-0.895-2-2zM5.515 24.485c0 0 0 0 0 0 0-1.105 0.895-2 2-2s2 0.895 2 2c0 0 0 0 0 0 0 1.105-0.895 2-2 2s-2-0.895-2-2zM4.515 7.515c0 0 0 0 0 0 0-1.657 1.343-3 3-3s3 1.343 3 3c0 0 0 0 0 0 0 1.657-1.343 3-3 3s-3-1.343-3-3zM1.75 16c0-1.243 1.007-2.25 2.25-2.25s2.25 1.007 2.25 2.25c0 1.243-1.007 2.25-2.25 2.25s-2.25-1.007-2.25-2.25z" />
    </svg>
  ),
  chevronRight: (
    <svg viewBox="0 0 16 16">
      <path d="M5.5 0l-2 2 6 6-6 6 2 2 8-8-8-8z" />
    </svg>
  ),
  chevronLeft: (
    <svg viewBox="0 0 16 16">
      <path d="M10.5 16l2-2-6-6 6-6-2-2-8 8 8 8z" />
    </svg>
  ),
  info: (
    <svg viewBox="0 0 16 16">
      <path d="M7 4.75c0-0.412 0.338-0.75 0.75-0.75h0.5c0.412 0 0.75 0.338 0.75 0.75v0.5c0 0.412-0.338 0.75-0.75 0.75h-0.5c-0.412 0-0.75-0.338-0.75-0.75v-0.5z" />
      <path d="M10 12h-4v-1h1v-3h-1v-1h3v4h1z" />
      <path d="M8 0c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM8 14.5c-3.59 0-6.5-2.91-6.5-6.5s2.91-6.5 6.5-6.5 6.5 2.91 6.5 6.5-2.91 6.5-6.5 6.5z" />
    </svg>
  ),

  location: (
    <svg viewBox="0 0 32 32">
      <g fill="currentColor" transform="translate(2, 6)">
        <path d="M22.77.05,0,7.93V9.08l11.64,3.28h0L14.92,24h1.15L24,1.23A.93.93,0,0,0,22.77.05Z" />
      </g>
    </svg>
  ),
};

const Icon = (props) => {
  const { name } = props;
  return <span className="icon">{icons[name]}</span>;
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Icon;
