import React, { useCallback, useState, useMemo, useContext } from "react";
import { MapContext, VectorSource } from "react-openlayers";
import { Style, Fill, Stroke, Circle } from "ol/style";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { useTranslate } from "react-translate";
import "./ZoomToPosition.scss";
import Icon from "./Icon";
import config from "../config";
import Loading from "./Loading";

const myLocationStyle = new Style({
  image: new Circle({
    radius: 6,
    fill: new Fill({
      color: "#145be5",
    }),
    stroke: new Stroke({
      color: "white",
      width: 2,
    }),
  }),
});

const none = [];

const ZoomToPosition = () => {
  const t = useTranslate("Map");
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState();
  const { setCenterAndZoom } = useContext(MapContext);

  const onZoomToPosition = useCallback(() => {
    if (location) {
      setCenterAndZoom(location, 14);
      return;
    }
    setLoading(true);
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const p = new Point([
          position.coords.longitude,
          position.coords.latitude,
        ]);
        p.transform("EPSG:4326", "EPSG:" + config.mapProjCode);
        const l = [p.getCoordinates()[0], p.getCoordinates()[1]];
        setLoading(false);
        setLocation(l);
        setCenterAndZoom(l, 14);
      },
      (err) => {
        console.error(err);
        setLoading(false);
      },
      {
        enableHighAccuracy: true,
      }
    );
  }, [location, setCenterAndZoom]);

  const features = useMemo(() => {
    if (!location) return none;

    const p = new Point(location);
    const f = new Feature(p);
    return [f];
  }, [location]);

  return (
    <>
      <VectorSource
        layerName="myLocation"
        features={features}
        style={myLocationStyle}
        zIndex={100}
      />

      <button
        className="btn-map primary zoom-to-position"
        onClick={onZoomToPosition}
        disabled={loading}
        title={t("zoomToPosition")}
      >
        {loading ? <Loading /> : <Icon name="location" />}
      </button>
    </>
  );
};

export default ZoomToPosition;
