export default {
  locale: "no",

  App: {
    zoomInTip: "Zoom inn",
    zoomOutTip: "Zoom ut",
  },

  Map: {
    zoomToPosition: "Gå til min posisjon",
  },

  ArticleList: {
    readMore: "Les mer",
  },

  Article: {
    laringsopplegg: "Læringsopplegg",
    forfatter: "Forfatter",
    kommune: "Kommune",
    fagkomb: "Fag",
    beskrivelse: "Beskrivelse av læringsopplegg",
    lenke: "Lenke",
    arstid: "Årstid",
    skole: "Skole",
    klassetrinn: "Klassetrinn",
    larestedtype: "Lærestedtype",
    utstyr: "Utstyr",
    tidsbruk: "Tidsbruk",
    kompetansemal: "Kompetansemål",
    dat_sistendret: "Dato sist endret",
    lokaltstedsnavn: "Lokalt stedsnavn",
  },
};
