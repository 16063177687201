import React, { useMemo, useEffect } from "react";
import Article from "../components/Article";
import Icon from "../components/Icon";
import Title from "../components/Title";
import { useParams, Link } from "react-router-dom";
import { VectorSource, defaultStyle, olUtils } from "react-openlayers";
import { useArticle } from "../hooks/useArticles";
import useArticleConfig from "../hooks/useArticleConfig";
import { useZoomToGeomWkt } from "../hooks/useZoomTo";
import "./ArticleDetailView.scss";
import Loading from "../components/Loading";

const style = defaultStyle({
  pointRadius: 15,
  zIndex: 1,
  fillColor: "#0066ff",
  strokeColor: "#ffffff",
  strokeWidth: 2,
});

const ArticleDetailView = () => {
  const { articleId } = useParams();
  const articleConfig = useArticleConfig();
  const { article, loading } = useArticle(articleId);

  useZoomToGeomWkt(article && article.geom_wkt, 16);

  const features = useMemo(
    () =>
      article && article.geom_wkt
        ? [olUtils.createFeatureFromWkt(article.geom_wkt)]
        : [],
    [article]
  );

  useEffect(() => window.scrollTo(0, 0), [articleId]);

  return (
    <div className="article-detail-view--container">
      <Title title="Stedbasert Læring" />
      <VectorSource
        layerName="article-detail-feature"
        features={features}
        style={style}
      />

      <div className="article-window">
        {article && articleConfig ? (
          <Article articleConfig={articleConfig} article={article} />
        ) : loading ? (
          <Loading />
        ) : (
          <p>Kunne ikke laste innhold.</p>
        )}
        <Link to="/" className="article-detail-view--close-btn">
          <Icon name="cross" />
        </Link>
      </div>
    </div>
  );
};

export default ArticleDetailView;
