import React from "react";
import ArticleView from "../views/ArticleView";
import ArticleDetailView from "../views/ArticleDetailView";
import CoreLayout from "../containers/CoreLayout";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

const AppRouter = () => {
  return (
    <Router>
      <Switch>
        <CoreLayout>
          <Switch>
            <Redirect path="/article/sx9wbd1s/:id" to="/artikkel/:id" />
            <Route path="/artikkel/:articleId" component={ArticleDetailView} />
            <Route exact path="/" component={ArticleView} />
            <Redirect to="/" />
          </Switch>
        </CoreLayout>
      </Switch>
    </Router>
  );
};

export default AppRouter;
