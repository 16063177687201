import React, { useState, useEffect } from "react";
import useArticleConfig from "../hooks/useArticleConfig";
import request from "superagent";
import config from "../config";
import { MapListener } from "./Features";
import { Link } from "react-router-dom";
import "./Search.scss";
import Icon from "./Icon";
import Loading from "./Loading";

function useDebounce(input) {
  const [out, setOut] = useState(input);

  useEffect(() => {
    if (!input) {
      setOut(input);
      return;
    }
    const tId = setTimeout(() => setOut(input), 500);
    return () => clearTimeout(tId);
  }, [input]);

  return out;
}

function useSearch(query, extent) {
  const articleConfig = useArticleConfig();
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!articleConfig || !query || !extent) {
      setResults([]);
      setLoading(false);
      return;
    }

    setLoading(true);
    const req = request
      .post(config.adaptiveUrl + "WebServices/search/SearchProxy.asmx/Search")
      .send({
        request: {
          bbox: {
            left: extent[0],
            bottom: extent[1],
            right: extent[2],
            top: extent[3],
          },
          q: query + "*",
          limit: 100,
          srsid: config.mapProjCode,
          start: 0,
          layers: articleConfig.value.layerId.toString(),
        },
      });

    req
      .then((res) => res.body)
      .then((res) => {
        setLoading(false);
        if (!res.d || !res.d.success) {
          setResults([]);
          console.error(res.d);
          return;
        }

        setResults(res.d.records);
      })
      .catch((err) => {
        if (err.code && err.code === "ABORTED") return;
        setLoading(false);

        setResults([]);
        console.error(err);
      });

    return () => req.abort();
  }, [articleConfig, query, extent]);

  return { results, loading };
}

const Search = () => {
  const [query, setQuery] = useState("");
  const [extent, setExtent] = useState();
  const bufferedQuery = useDebounce(query);

  const { results, loading } = useSearch(bufferedQuery, extent);

  return (
    <div className="search">
      <MapListener onExtentChange={setExtent} />
      <input
        type="text"
        name="search"
        id="search"
        placeholder="Søk"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      <button
        type="button"
        onClick={(e) => setQuery("")}
        title="Tilbakestill søk"
      >
        <Icon name="cross" />
      </button>
      {loading && <Loading />}
      <div className="search-results">
        {results.map((result) => (
          <Link
            to={"/artikkel/" + result.uid.split(".").pop()}
            key={result.uid}
            className="search-result"
            onClick={() => setQuery("")}
          >
            {result.title}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Search;
