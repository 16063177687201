import React from "react";
import config from "../config";
import { useTranslate } from "react-translate";
import { useArticleMedia } from "../hooks/useArticles";

function formatValue(v) {
  if (/\/Date\((\d+)\)\//.test(v)) {
    return new Date(parseInt(v.substring(6))).toLocaleDateString("nb");
  }
  if (/^https?:\/\//.test(v)) {
    return `<a href="${v}" target="_blank" rel="noreferrer noopener">${v}</a>`;
  }
  return v;
}

const Article = ({ articleConfig, article }) => {
  const t = useTranslate("Article");
  const image =
    articleConfig && article[articleConfig.config.columns.image]
      ? config.adaptiveUrl +
        "WebServices/generic/Media.asmx/Download?uuid=" +
        article[articleConfig.config.columns.image]
      : false;

  const title = articleConfig && article[articleConfig.config.columns.title];
  const content =
    articleConfig && article[articleConfig.config.columns.content];

  const { media } = useArticleMedia(article.uuid);

  return (
    <div className="article">
      {image && (
        <a href={image} target="_blank" rel="noreferrer noopener">
          <img src={image + "&thumbnail_size=large"} alt="" />
        </a>
      )}
      {title && title !== "null" && <h3>{title}</h3>}
      <div className="content" dangerouslySetInnerHTML={{ __html: content }} />
      {media && !!media.length && (
        <div className="attachments">
          <h5>Vedlegg (trykk for å åpne/laste ned)</h5>
          <div>
            {media.map((media) => {
              const url =
                config.adaptiveUrl +
                "WebServices/generic/Media.asmx/Download?uuid=" +
                media.uuid;
              return (
                <a
                  key={media.uuid}
                  href={url}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img
                    src={url + "&thumbnail_size=small"}
                    alt={media.name}
                    title={"Åpne vedlegg: " + media.name}
                  />
                  {media.name && /\.[a-zA-Z0-9]{2,4}$/.test(media.name) && (
                    <span className="type">{media.name.split(".").pop()}</span>
                  )}
                </a>
              );
            })}
          </div>
        </div>
      )}
      <div className="additionalColumns">
        {articleConfig.config.columns.additionalColumns.map((c) => {
          let n = t(c);
          if (n.indexOf("Article.") === 0) n = c;
          return (
            <div className="additionalColumn" key={c}>
              <span className="additionalColumnLabel">{n}</span>
              <span
                className="additionalColumnContent"
                dangerouslySetInnerHTML={{ __html: formatValue(article[c]) }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Article;
