import React, { Component } from "react";
import { Map, WmsLayer, Zoom } from "react-openlayers";
import { TranslatorProvider, translate } from "react-translate";
import translations from "./translations";
import config from "./config/";
import Router from "./routes";
import ZoomToPosition from "./components/ZoomToPosition";
import XYZLayer from "./components/XyzLayer";

class App extends Component {
  getTranslations(lang) {
    return translations[lang];
  }

  render() {
    return (
      <TranslatorProvider translations={this.getTranslations("no")}>
        <MapWrapper>
          <WmsLayer
            id="world"
            key="world"
            name="Verden"
            uri="https://map-adaptive3.avinet.no/mapserv.ashx?map=public/world&amp;color=226 244 255"
            layerName="ocean_3857"
            version="1.3.0"
            crossOrigin="Anonymous"
            singleTile
            zIndex={-4}
          />
          <XYZLayer
            url="https://cache.kartverket.no/v1/wmts/1.0.0/topo/default/webmercator/{z}/{y}/{x}.png"
            zIndex={-3}
          />
          {/* <WmsLayer
            id="baselayer"
            uri="https://opencache.statkart.no/gatekeeper/gk/gk.open"
            layerName="topo4"
            version="1.1.1"
          /> */}
          <Router />
        </MapWrapper>
      </TranslatorProvider>
    );
  }
}

export default App;

const MapWrapper = translate("App")((props) => {
  const { children } = props;

  return (
    <Map
      center={undefined}
      zoom={undefined}
      minZoom={config.minZoom}
      maxZoom={config.maxZoom}
      extent={config.initExtent}
      baatUrl={config.adaptiveUrl}
    >
      <ZoomToPosition />
      <Zoom />
      {children}
    </Map>
  );
});
