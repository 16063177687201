import React, {
  useState,
  useContext,
  useMemo,
  useCallback,
  useRef,
  useEffect,
} from "react";
import useArticles from "../hooks/useArticles";
import { useHistory } from "react-router-dom";
import {
  ClusterSource,
  MapContext,
  clusterStyle,
  defaultStyle,
  Select,
  clusterText,
  provideMapState,
} from "react-openlayers";

const style = clusterStyle({
  clusterStyle: (cluster, resolution) => {
    const count = cluster.get("features").length;
    return defaultStyle({
      labelText: clusterText({
        offsetY: 1,
        color: "#000",
      }),
      pointRadius: 10 + Math.min(20, count / 5),
      fillColor: "#0cf",
      strokeColor: "#fff",
      strokeWidth: 2,
    })(cluster, resolution);
  },
  featureStyle: defaultStyle({
    fillColor: "#0cf",
    strokeColor: "#fff",
    strokeWidth: 2,
  }),
});

export const MapListener = provideMapState(
  ({
    mapCenter,
    mapZoom,
    mapExtent,
    onCenterChange,
    onZoomChange,
    onExtentChange,
    onStateChange,
    extent,
    fitViewAndZoom,
  }) => {
    useEffect(() => onStateChange && onStateChange(mapCenter, mapZoom), [
      mapCenter,
      mapZoom,
      onStateChange,
    ]);
    useEffect(() => onCenterChange && onCenterChange(mapCenter), [
      mapCenter,
      onCenterChange,
    ]);
    useEffect(() => onZoomChange && onZoomChange(mapZoom), [
      mapZoom,
      onZoomChange,
    ]);
    useEffect(() => onExtentChange && onExtentChange(mapExtent), [
      mapExtent,
      onExtentChange,
    ]);
    useEffect(() => extent && fitViewAndZoom && fitViewAndZoom(extent), [
      extent,
      fitViewAndZoom,
    ]);
    return null;
  }
);

const Features = () => {
  const history = useHistory();
  const { articles } = useArticles();
  const { setCenterAndZoom } = useContext(MapContext);
  const [zoom, setZoom] = useState(0);
  const zoomRef = useRef(zoom);

  useEffect(() => {
    zoomRef.current = zoom;
  }, [zoom]);

  const onSelect = useCallback(
    (cluster) => {
      const f = cluster[0].get("features");
      if (f && f.length > 1) {
        setCenterAndZoom(
          cluster[0].getGeometry().getCoordinates(),
          zoomRef.current < 10 ? zoomRef.current + 2 : zoomRef.current + 1
        );
      } else if (f.length === 1) {
        history.push("/artikkel/" + f[0].get("id"));
      }
      return false;
    },
    [zoomRef, setCenterAndZoom, history]
  );

  const features = useMemo(
    () => articles && articles.map((item) => item.feature),
    [articles]
  );

  return (
    <>
      <MapListener onZoomChange={setZoom} />
      <ClusterSource
        layerName="article-features"
        features={features}
        style={style}
      >
        <Select onSelect={onSelect} />
      </ClusterSource>
    </>
  );
};

export default Features;
