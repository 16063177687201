import React, { useMemo, useState, useContext } from "react";
import useArticles from "../hooks/useArticles";
import config from "../config";
import { Link } from "react-router-dom";
import { MapListener } from "../components/Features";
import { MapContext } from "react-openlayers";
import { containsCoordinate } from "ol/extent";
import Point from "ol/geom/Point";
import { getDistance } from "ol/sphere";
import "./ArticleView.scss";
import Loading from "../components/Loading";

const Article = ({ id, laringsopplegg, fagkomb, media }) => {
  return (
    <div
      className={[
        "articleList--entry",
        "articleList--thumbnail",
        media && "articleList--withThumbnail",
      ].join(" ")}
    >
      {media && (
        <img
          src={
            config.adaptiveUrl +
            "WebServices/generic/Media.asmx/Download?uuid=" +
            media +
            "&thumbnail_size=medium"
          }
          alt=""
        />
      )}
      <div className="caption">
        <h4>{laringsopplegg}</h4>
        <div className="articleList--content">{fagkomb}</div>
        <Link to={"/artikkel/" + id} className="pull-right btn btn-link">
          Les mer
        </Link>
      </div>
    </div>
  );
};

const ArticleView = () => {
  const { articles, loading } = useArticles();
  const { getPaddedCenter } = useContext(MapContext);
  const [extent, setExtent] = useState();

  const sortedArticles = useMemo(() => {
    if (!articles || !extent) return [];

    const filteredArticles = articles.filter(
      (item) =>
        item.feature &&
        containsCoordinate(extent, item.feature.getGeometry().getCoordinates())
    );

    const center = getPaddedCenter();
    const centerWgs84 = new Point(center)
      .transform("EPSG:3857", "EPSG:4326")
      .getCoordinates();

    filteredArticles.forEach((item) => {
      if (!item.feature) return;
      if (!item._wgs84)
        item._wgs84 = item.feature
          .getGeometry()
          .clone()
          .transform("EPSG:3857", "EPSG:4326");
      item._dist = getDistance(item._wgs84.getCoordinates(), centerWgs84);
    });

    filteredArticles.sort((a, b) => a._dist - b._dist);

    return filteredArticles;
  }, [articles, extent, getPaddedCenter]);

  return (
    <div className="article-view--container">
      <MapListener onExtentChange={setExtent} />
      <div className="article-view--content" id="articleView-scrollPane">
        <div className="article-list articleList--list">
          {loading ? (
            <Loading />
          ) : (
            sortedArticles.map((article) => (
              <Article key={article.id} {...article} />
            ))
          )}
        </div>
        {!loading &&
        sortedArticles.length < 100 &&
        articles.length > sortedArticles.length ? (
          <p className="info">
            Zoom ut eller flytt kartet for å se flere artikler.
          </p>
        ) : null}
      </div>
    </div>
  );
};

export default ArticleView;
