import { useEffect, useState } from "react";
import request from "superagent";
import config from "../config";

const cache = {};

export default function useArticleConfig() {
  const [articleConfig, setArticleConfig] = useState(cache.articleConfig);

  useEffect(() => {
    if (cache.articleConfig) {
      setArticleConfig(cache.articleConfig);
      return;
    }

    request
      .post(
        config.adaptiveUrl +
          "WebServices/ArticleModule/ArticleModuleConfig.asmx/ReadArticleModuleConfig"
      )
      .send({ amuuid: config.articleModuleUuid })
      .then((res) => res.body)
      .then((res) => {
        if (!res.sources) {
          console.error(res);
          return;
        }
        const source = res.sources[0];
        source.columns = Object.values(source.config.columns)
          .flat()
          .filter((c) => !!c);
        cache.articleConfig = source;
        setArticleConfig(source);
      })
      .catch((err) => {
        console.error(err);
        return;
      });
  }, []);

  return articleConfig;
}
