const config = {
  appName: "Stedbasert Læring",
  adaptiveUrl: "https://friluftsforvaltning.avinet.no/",
  guiUrl: "https://www.stedbasert.avinet.no/",
  articleModuleUuid: "58c0d379-8032-4971-b31b-e7a92d3ddd57",
  digiThemeUuid: "fd8e6795-6a00-47e4-9ea5-0e07a3cf5856",
  mapProjCode: "3857",
  mapProjection: "EPSG:3857",
  minZoom: 3,
  maxZoom: 18,
  initExtent: [-525327, 7600937, 4656211, 11819636],
};

export default config;
